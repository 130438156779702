<template>
	<div class="doctor_management">
		<el-row class="bread_crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/RoleManagement' }">系统管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/RoleManagement' }">权限管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/Authorization' }">授权</el-breadcrumb-item>
			</el-breadcrumb>
		</el-row>
	<div class="public_bg">
		<!-- header -->
		<!-- <v-header-datail changeState="详情页面" :routerPaths="paths"></v-header-datail> -->
		<el-tabs style="margin-top:20px" v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="权限功能" name="first">
				<el-row style="margin-top: 20px;">
					<template>
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
						<div style="margin: 15px 0;"></div>
						<el-checkbox-group v-model="checkedbutton" @change="handleCheckedCitiesChange">
							<div v-for="(item,index) in buttonList" :key="index" style="margin-top: 10px;">
								<el-checkbox :label="item.buttonName" :key="item.buttonName">{{item.buttonName}}</el-checkbox>
							</div>
						</el-checkbox-group>
					</template>
					<div style="margin-top: 20px;">
						<el-button type="primary" @click="submits">提交</el-button>
					</div>
				</el-row>
			</el-tab-pane>
			<el-tab-pane label="数据权限" name="second">
				<el-table
					:data="rmdfrData"
					style="width: 100%">
					<el-table-column type="expand">
					<template slot-scope="props">
						<el-button type="text" @click="editors(props.row.dataFieldName,props.row.dataFieldId)">编辑</el-button>
						<template v-for="item in props.row.conditionVoList">
						<span style="margin-left:20px;">{{item.dataFieldName}}</span>
						</template>
					</template>
					</el-table-column>
					<el-table-column
					label="名称"
					prop="dataFieldName">
					</el-table-column>
					<el-table-column
					label="数据情况"
					>
					<template slot-scope="scope">
						<span v-if="!scope.row.conditionVoList">全部</span>
						<span v-else>部分</span>
					</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<!-- 授权模态框 -->
		<el-dialog :title="'选择'+dataFieldName" :visible.sync="AuthorityDialog" width="40%">
			<div>
				<tree-transfer filter  :title="['未授权', '已授权']" :button-text="['取消','授权']" :from_data='fromData' :to_data='toData'
				@addBtn='changedata'  @removeBtn='changedata'></tree-transfer>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="" @click="AuthorityDialog = false">取消</el-button>
				<el-button type="primary" @click="insertSure">确认</el-button>
				
			</span>
		</el-dialog>
		</div>
	</div>
</template>

<script>
    import treeTransfer from 'el-tree-transfer'
	import vHeaderDatail from "../common/HeaderDatail.vue"; //引入详情页返回组件
	export default {
		components: {
			vHeaderDatail,
			treeTransfer
		},
		data() {
			return {
				fromData: [], //授权原数据
				toData: [], //授权目标数据
				AuthorityDialog:false,
				rmdfrData:[],//数据权限
				paths: '/Authorization',
				checkedbutton: [],
				checkAll: false,
				activeName: 'first',
				isIndeterminate: true,
				buttonList: [],
				insertDtoList:[],
				updateDtoList:[],
				dataFieldName:""
			};
		},
		methods: {
			//授权确定
			async QueryrmdfrDataUpdateQuery() {
				let info = {
					insertData: this.addList,
			        updateData: this.delList
				}
				let res = await this.$Http.rmdfrDataUpdateQuery(info);
				if (res.code == '200') {
					this.$message.success(res.message);
					this.AuthorityDialog = false;
					this.QueryrmdfrQuery();
				} else {
					this.$message.error(res.message);
				}
			},
			// 授权确定
			insertSure() {
                 this.QueryrmdfrDataUpdateQuery();
			},
			arrayToList(tree, arr=[]){
				tree.map((val)=>{
					let temp = JSON.parse(JSON.stringify(val))
					delete temp.children
					arr.push(temp)
					if(val.children){
					this.arrayToList(val.children, arr)
					}
				})
				return arr
			},
			//更改授权穿梭框
			changedata(fromdata,todata,obj){
				this.addList = [];
				this.delList =[];
				this.arrayToList(todata, this.addList);
				this.arrayToList(fromdata, this.delList);
			},
			//授权数据
			async QueryrmdfrDataQuery(dataFieldId) {
				let info = {
					roleId: this.roleId,
					menuId: this.menuId,
					dataFieldId:dataFieldId
				}
				let res = await this.$Http.rmdfrDataQuery(info);
				if (res.code == '200') {
					this.fromData = res.data.fromData;
					this.toData = res.data.toData;
				} else {
					this.$message.error(res.message);
				}
			},
			//编辑
			editors(dataFieldName,dataFieldId){
				this.AuthorityDialog = true;
				this.fromData = [];
				this.toData =[];
				this.dataFieldName=dataFieldName;
				this.QueryrmdfrDataQuery(dataFieldId);
			},
			//修改按钮授权
			async QueryRoleMenuButtonUpdate() {
				let info = {
					insertDtoList: this.insertDtoList,
					updateDtoList: this.updateDtoList
				}
				let res = await this.$Http.RoleMenuButtonUpdate(info);
				if (res.code == '200') {
					this.QueryRoleMenuButton(this.roleId, this.menuId);
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//数据权限
			async QueryrmdfrQuery() {
				let info = {
					roleId: this.roleId,
					menuId: this.menuId
				}
				let res = await this.$Http.rmdfrQuery(info);
				if (res.code == '200') {
					this.rmdfrData=res.data;
				} else {
					this.rmdfrData=[];
					this.$message.error(res.message);
				}
			},
			
			//提交
			submits() {
                this.QueryRoleMenuButtonUpdate();
			},
			handleCheckedCitiesChange(value) {
				this.HandleEvents();
			},
			HandleEvents(){
				let addButton = [];
				let delButton = [];
				this.insertDtoList = [];
				this.updateDtoList = [];
				this.checkedbutton.map((val)=>{
				  if(!this.defaultbutton.includes(val)){
				    addButton.push(val)
				  }
				})
				this.defaultbutton.map((val)=>{
				  if(!this.checkedbutton.includes(val)){
				    delButton.push(val)
				  }
				})
				this.buttonList.map((val)=>{
				  addButton.map((addItem)=>{
				    if(addItem == val.buttonName){
				      this.insertDtoList.push({
				        "roleId": val.roleId,
				        "menuId": val.menuId,
				        "buttonId": val.buttonId
				      })
				    }
				  })
				  delButton.map((deltem)=>{
				    if(deltem == val.buttonName){
				      this.updateDtoList.push({
				        "relationId": val.relationId,
				        "roleId": val.roleId,
				        "menuId": val.menuId,
				        "buttonId": val.buttonId,
				        "buttonName": val.buttonName
				      })
				    }
				  })
				})
				console.log(this.insertDtoList,"insertDtoList")
				console.log(this.updateDtoList,"updateDtoList")
			},
			handleCheckAllChange(val) {
				console.log(val)
				this.checkedbutton = val ? this.buttons : [];
				this.HandleEvents();
				this.isIndeterminate = false;
			},
			handleClick(tab, event) {
				console.log(tab.label);
				if(tab.label=="数据权限"){
					this.QueryrmdfrQuery();
				}else{
					this.QueryRoleMenuButton(this.roleId, this.menuId);
				}
			},
			//获取按钮数据权限
			async QueryRoleMenuButton(roleId, menuId) {
				let info = {
					roleId: roleId,
					menuId: menuId
				}
				let res = await this.$Http.RoleMenuButtonQuery(info);
				if (res.code == '200') {
					this.buttonList = res.data
					this.checkedbutton = [];
					this.defaultbutton = [];
					this.buttons = [];
					res.data.map((item, index, arr) => {
						this.buttons.push(item.buttonName)
						if (item.status == "1") {
							this.defaultbutton.push(item.buttonName)
							this.checkedbutton.push(item.buttonName)
						} else {}
					});

				} else {
					this.$message.error(res.message);
				}
			},
		},
		mounted() {
			this.roleId = this.$route.query.roleId;
			this.menuId = this.$route.query.menuId;
			this.QueryRoleMenuButton(this.roleId, this.menuId);
		}
	};
</script>

<style>
</style>
